.live-page {
    display: flex;
    height: 100vh;
    width: 90%;
    margin: 0 auto;

    #live-page-image { 	background-image: url("../../assets/MK_3921.jpg");  }
    
    &__gigs {
	height: 90vh;
	width: 50%;
	overflow:auto;
	display: flex;
	flex-direction: column;

	h3 {
	    margin-left: 1vw;
	    padding-left: 5px;
	}
	
	table {
	    overflow: auto;
	    font-size: 1em;
	    margin-left: 1vw;
	

	    border-spacing: 0px;
	    
	    tr {
		
		th {
		    text-align: left;
		    padding: 0 5px;
		    height: 5vh;
		}

		td {
		    padding: 5px;
		    height: 5vh;
		}
	    }
	}
    }
}

@media only screen and (max-width: 600px) {
    .live-page {
	display: flex;
	flex-direction: column;
	height: fit-content;

	&__image {
	    width: 100%;
	}

	&__gigs {
	    width: 100%;
	    
	    margin: 0;
	    height: 100vh;
	}
    }
}
	    
