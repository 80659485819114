$stream-color: #464646;

.infinity-mirror-page {
    display: flex;
    flex-direction: column;
    overflow: auto;

    #feedback-headline { margin: auto; }
    
    .image-carousel {

    }
    
    &__container {
	height: 100vh;
	width: 90%;
	margin: 0 auto;
	display: flex;
	
	&__description {
	    color: $stream-color;
	    margin: 0 5vw 0 5vw;
	    display: flex;
	    flex-direction: column;
	    text-align: center;

	    p { margin-bottom: 0vh; }
	    h2 { margin-bottom: 0vh; }

	}
	
	
	#infinity-mirror-main-poster { background-image: url("../../assets/infinity_mirror/square.png"); }
	&__about {
	    width: 50%;
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    height: fit-content;

	    &__logos {
		display: flex;
		justify-content: center;
		margin: 3vh 0 -6vh 0;

		.logo {
		    margin: 0 2vw;
		    width: 16vh;
		    height: 15vh; /* You must set a specified height */
		}
		#free-festival {
		    background-image: url("../../assets/infinity_mirror/free_festival_logo.png");
		}
		#laughing-horse { background-image: url("../../assets/infinity_mirror/laughing_horse_logo.jpeg"); }
	    }
	}
    }
    .feedback {
	margin-top: 5vh;
	width: 100vw;
	height: 100vh; /* You must set a specified height */
	background-size: contain;
    }

    #audience-feedback { background-image: url("../../assets/lil_saffron/audience_feedback_edfringe_site.png"); }
    #twitter-feedback { background-image: url("../../assets/lil_saffron/twitter_feedback.png"); }
}


@media only screen and (max-width: 600px) {
    .infinity-mirror-page {
	flex-direction: column;
	margin-bottom: 3vh;

	.feedback {
	    height: 55vw;

	}

	#feedback-headline { margin-top: 3vh; }
	
	&__container {
	    display: flex;
	    flex-direction: column;
	    height: fit-content;
	    margin-bottom: 5vh;
	    
	    &__about {
		width: 100%;
		height: fit-content;
		
		.quote-carousel { display: none; }
		
	    }
	    
	    &__description {
		display: flex;
		flex-direction: column;
		
		h2 {
		    text-align: center;
		    margin: 3vh auto 2vh;
		}

		
	    }
	}
    }
}
