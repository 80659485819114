.videos-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;



    &__header {
	margin-left: 4.5vw;
	margin-top: 1vh;
	color: #464646;
    }
    
    &__container {
	display: flex;
	height: 90vh;
	width: 92vw;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin: auto;

	.video-page.selected {
	    width: 100vw;
	    height: 100vh;

	    .video-page__iframe {
		width: 100vw;
		height: 100vh;
	    }
	}
	
	.video-page {
	    width: 30vw;
	    height: 30vh;
	    margin: .5vh;

	    &__iframe {
		width: 30vw;
		height: 30vh;
		position: relative;
	    }
	    
	    &__overlay {
		background-color: #cccccc; /* Used if the image is unavailable */
		height: 30vh; /* You must set a specified height */
		width: 30vw;
		background-position: center; /* Center the image */
		background-repeat: no-repeat; /* Do not repeat the image */
		background-size: cover; /* Resize the background image to cover the entire container */
		position: relative;
		z-index: 1000;
	    }
	}
    }
}

@media only screen and (max-width: 600px) {
    .videos-page {
	
	&__header {
	    font-size: 1.5em;
	    width: fit-content;
	    margin: 3vh auto!important;
	}

	&__container {
	    width: 100vw;

	    
	    .video-page {
		width: 98vw;;
		height: 45vw;
	    }
	}
    }
}
