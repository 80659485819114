.gallery-page {
    display: flex;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10vh;

    #image-0 { background-image: url('../../assets/sweater_MK_4099.jpg'); }
    #image-1 { background-image: url('../../assets/MK_4104.jpg'); }
    #image-2 { background-image: url('../../assets/denim_MK_3782.jpg'); }
    #image-3 { background-image: url('../../assets/MK_3921.jpg'); }
    #image-4 { background-image: url('../../assets/MK_3924.jpg'); }
    #image-5 { background-image: url('../../assets/Michael_Kunze_Comedy_Headshot.jpg'); }
    #image-6 {
	background-image: url('../../assets/jean1.jpg');
	background-position: center;
    }
    #image-7 { background-image: url('../../assets/hawaii1.jpg'); }
    #image-8 {
	background-image: url('../../assets/jean2.jpg');
	background-position: center;
    }
    #image-9 { background-image: url('../../assets/hawaii2.jpg'); }
    
    .gallery-image {
	width: 13vw;
	height: 13vw;
    }
    
    &__right-side {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	&__images-container {
	    display: flex;
	    width: 40vw;
	    height: 40vw;
	    flex-wrap: wrap;
	    justify-content: space-evenly;
	    margin: auto;

	}
    }
}

@media only screen and (max-width: 600px) {
    .gallery-page {
	flex-direction: column;

	&__right-side {
	    width: 100%;

	    &__images-container {
		display: flex;
		width: 95vw;
		height: 90vw;
		flex-wrap: wrap;
		justify-content: space-evenly;
		margin: auto;
		margin-top: 5vh;

		.gallery-image {
		    width: 30vw;
		    height: 30vw;
		}
	    }
	    
	}
    }
}
