.video-page {
    width: 99vw;
    height: 98vh;
    overflow: hidden;
    margin: .5vw auto;
    
    &__iframe {
	width: 100vw;
	height: 100vh;
	position: relative;
    }

    &__overlay {
	background-color: #cccccc; /* Used if the image is unavailable */
	height: 100vh; /* You must set a specified height */
	width: 100vw;
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
	position: relative;
	z-index: 1000;
	cursor: pointer;
    }

    &__overlay#totes { background-image: url("../../assets/video_overlays/totes_overlay.png"); }
    &__overlay#trolley { background-image: url("../../assets/video_overlays/trolleys_overlay.png"); }
    &__overlay#apocalypse { background-image: url("../../assets/video_overlays/apocalypse_overlay.png"); }
    &__overlay#dr_sinister { background-image: url("../../assets/video_overlays/dr_sinister_overlay.png"); }
    &__overlay#odg { background-image: url("../../assets/video_overlays/odg_overlay.png"); }
    &__overlay#open_dad_night { background-image: url("../../assets/video_overlays/open_dad_night_overlay.png"); }
    &__overlay#too_much_to_touch { background-image: url("../../assets/video_overlays/too_much_to_touch_overlay.png"); }
    &__overlay#unfollowing { background-image: url("../../assets/video_overlays/unfollowing_overlay.png"); }
    &__overlay#dog-lovers { background-image: url("../../assets/video_overlays/dog-lovers_overlay.jpg"); }
    &__overlay#leaving_for_work { background-image: url("../../assets/video_overlays/leaving_for_work_overlay.jpg"); }
    &__overlay#gotcha { background-image: url("../../assets/video_overlays/gotcha_overlay.png"); }
    &__overlay#spyhole { background-image: url("../../assets/video_overlays/spyhole_overlay.png"); }
    &__overlay#politeness { background-image: url("../../assets/video_overlays/politeness_overlay.png"); }
    &__overlay#netflix-mystery { background-image: url("../../assets/video_overlays/netflix-mystery_overlay.png"); }
    &__overlay#talking-dessert { background-image: url("../../assets/video_overlays/talking-dessert_overlay.png"); }
    &__overlay#vampire-plothole { background-image: url("../../assets/video_overlays/vampire-plothole_overlay.png"); }
    &__overlay#london-dungeon { background-image: url("../../assets/video_overlays/london-dungeon_overlay.png"); }
    &__overlay#chosen-one { background-image: url("../../assets/video_overlays/chosen-one_overlay.jpg"); }
}

@media only screen and (max-width: 600px) {
    .video-page {
	width: 100vw!important;
	height: 33vh!important;
	overflow: hidden;
	margin: 1vw auto!important;
	
	&__iframe {
	    width: 100vw!important;
	    height: 33vh!important;
	    position: relative;
	}
	
	&__overlay {
	    background-color: #cccccc; /* Used if the image is unavailable */
	    height: 33vh!important; /* You must set a specified height */
	    width: 100vw!important;
	    background-position: center; /* Center the image */
	    background-repeat: no-repeat; /* Do not repeat the image */
	    background-size: contain!important; /* Resize the background image to cover the entire container */
	    position: relative;
	    z-index: 1000;
	    cursor: pointer;
	}
    }
}

