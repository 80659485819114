$stream-color: #464646;

.blue-heart-theatre-page {
    display: flex;
    flex-direction: column;

    .image-carousel {
	#exile-gallery-4 { background-image: url('../../assets/exile/Exile photo 1.jpg'); }
	#exile-gallery-1 { background-image: url('../../assets/exile/Exile photo 2.jpg'); }
	#exile-gallery-2 { background-image: url('../../assets/exile/Exile photo 3.jpg'); }
	#exile-gallery-3 { background-image: url('../../assets/exile/Exile photo 4.jpg'); }
	#exile-gallery-0 { background-image: url('../../assets/exile/Exile photo 5.jpg'); }
	#exile-gallery-5 { background-image: url('../../assets/exile/Exile (L&U) - Poster.png'); }
	#exile-gallery-6 { background-image: url('../../assets/exile/exile.jpg'); }
    }

     &__container {
	height: 100vh;
	width: 90%;
	margin: 0 auto;
	display: flex;
	overflow: hidden;
	
	&__description {
	    color: $stream-color;
	    margin: 0 5vw 0 5vw;
	    display: flex;
	    flex-direction: column;

	    p { margin-bottom: -.5vh; }
	    h2 {
		margin-top: -1vh;
		margin-bottom: -.5vh;
	    }


	    &__productions {
		display: flex;
		width: 33vw;
		height: 17vw;
		flex-wrap: wrap;
		justify-content: space-evenly;
		margin: auto;
		margin-top: 4vh;

		.production-image {
		    width: 8vw;
		    height: 8vw;
		    position: relative;
		    background-color: #cccccc; /* Used if the image is unavailable */
		    background-position: center; /* Center the image */
		    background-repeat: no-repeat; /* Do not repeat the image */
		    background-size: cover; /* Resize the background image to cover the entire container */
		
		}
		
		#exile { background-image: url("../../assets/blue_heart_theatre/exile.png"); }
		#cock { background-image: url("../../assets/blue_heart_theatre/cock.png"); }
		#couples_pairs { background-image: url("../../assets/blue_heart_theatre/couples_pairs.png"); }
		#couples_pairs_2 { background-image: url("../../assets/blue_heart_theatre/couples_pairs_2.png"); }
		#baltimore_waltz { background-image: url("../../assets/blue_heart_theatre/baltimore_waltz.png"); }
		#fray { background-image: url("../../assets/blue_heart_theatre/fray.png"); }
		#mrs_toomey_house { background-image: url("../../assets/blue_heart_theatre/mrs_toomey_house.png"); }
		#tescos { background-image: url("../../assets/blue_heart_theatre/tescos.png"); }
		#icarus { background-image: url("../../assets/blue_heart_theatre/icarus.png"); }
	    }



	}
	
	
	&__image {
	    width: 50%;
	    background-color: #cccccc; /* Used if the image is unavailable */
	    height: 100vh; /* You must set a specified height */
	    background-position: top; /* Center the image */
	    background-repeat: no-repeat; /* Do not repeat the image */
	    background-size: cover; /* Resize the background image to cover the entire container */
	    
	}
	
	#main-image { background-image: url("../../assets/denim_MK_3782.jpg"); }
	#exile-main-poster { background-image: url("../../assets/exile/main_image.jpg"); }
	#blue-heart-theatre {
	    background-image: url("../../assets/exile/blue_heart_theatre.jpg");
	}
	&__about {
	    width: 50%;
	    display: flex;
	    flex-direction: column;
	    align-items: center;

	    .quote-carousel {
		margin-top: 0;
	    }

	    .quote-carousel__item__quote {
		font-size: 1em;
	    }
	    .quote-carousel__item__source {
		font-size: 1em;
	    }
	    
	}
    }
}

@media only screen and (max-width: 600px) {
    .blue-heart-theatre-page {
	
	&__container {
	    display: flex;
	    flex-direction: column;
	    height: fit-content;
	    margin-bottom: 5vh;
	    
	    &__about {
		width: 100%;
		height: fit-content;
		
		.quote-carousel { display: none; }
		
	    }
	    
	    &__description {
		display: flex;
		flex-direction: column;
		
		h2 {
		    text-align: center;
		    margin: 3vh auto 2vh;
		}

		&__productions {
		    display: flex;
		    width: 90vw;
		    height: 180vw;
		    flex-wrap: wrap;
		    margin: auto;
		    margin-top: 4vh;

		    .production-image {
			width: 45vw;
			height: 45vw;
		    }
		}
	    }
	}
	
    }
}
