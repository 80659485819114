$stream-color: #464646;

.contact-page {
    
    &__container {
	height: 100vh;
	width: 90%;
	margin: 0 auto;
	display: flex;
	overflow: hidden;
	margin-bottom: 10vh;
	
	#contact-image {
	    height: 100vh;
	    background-image: url("../../assets/sweater_MK_4099.jpg"); }

	&__submit-container {
	    width: 75%;
	    display: flex;
	    flex-direction: column;
	    border: 1px solid $stream-color;
	    border-radius: 1em;

	    &__submit-button {
		width: fit-content;
		margin: 2vh auto;
		border-radius: .5em;
		border: 1px solid $stream-color;
		padding: .5vw;
		cursor: pointer;
		color: $stream-color;
		
	    }

	    &__submit-button.disabled {
		cursor: not-allowed;
	    }

	    &__submit-button:hover { color: #000000; }

	    &__input-group:last-child { margin-bottom: 2vh; }
	    &__input-group {
		margin: 2vh;
		margin-bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		input {
		    padding: .5vw;
		    color: $stream-color;
		    border-radius: .5em;
		    border: 1px solid $stream-color;
		    width: 75%;
		}

		textarea {
		    width: 75%;
		    height: 6em;
		    padding: .5vw;
		    color: $stream-color;
		    border-radius: .5em;
		    border: 1px solid $stream-color;
		}
		
	    }
	 
	}
	
	&__description {
	    width: 50%;
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    overflow: auto;
	    color: $stream-color;

	    	    
	    p {
		max-width: 75%;
	    }

	}

	&__socials {
	    display: flex;
	    width: fit-content;
	    margin: 2vh 0;
	    
	    
	    i {
		font-size: 5vh;
		margin: 0 2vh;
		color: $stream-color;
	    }
	    
	    i.fa-spotlight {
		content: url('../../assets/SpotlightLogoRound.svg');
		height: 5vh;
	    }
	    
	    i.fa-spotlight:hover { content: url('../../assets/SpotlightLogoRound_hover.svg'); }
	    
	    i:hover {
		cursor: pointer;
		color: #000000;
	    }
	}
    }
}

@media only screen and (max-width: 600px) {
    .contact-page {
	flex-direction: column;
	margin-bottom: 3vh;

	.feedback {
	    height: 55vw;

	}

	#feedback-headline { margin-top: 3vh; }
	
	&__container {
	    display: flex;
	    flex-direction: column;
	    height: fit-content;
	    margin-bottom: 5vh;
	    
	    &__about {
		width: 100%;
		height: fit-content;
		
		.quote-carousel { display: none; }
		
	    }
	    
	    &__description {
		display: flex;
		flex-direction: column;
		width: 90%;
		text-align: center;
		
		h2 {
		    text-align: center;
		    margin: 3vh auto 2vh;
		}

		
	    }
	}
    }
}
