.improv-page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 110vh;
    
    &__container {
	margin-bottom: 5vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	#improv-quotes {
	    margin: 0;
	    overflow-x: hidden;
	    overflow-y: auto;
	    margin-bottom: 5vh;
	    height: fit-content;
	}
	
	&__description {
	    width: 80%;
	    margin-top: 5vh;
	    text-align: center;
	}

	&__team-container {
	    display: flex;
	    width: 92vw;
	    height: 65vh;
	    flex-wrap: wrap;
	    justify-content: space-evenly;
	    margin: auto;
	    
	    
	    &__team {
		width: 30vw;
		height: 30vw;
		margin-bottom: .5vw;

		&__overlay {
		    position: relative;
		    width: 30vw;
		    height: 30vw;
		    margin-top: -30vw;
		    display: flex;
		    flex-direction: column;
		    justify-content: center;
		    align-items: center;

		    &__name { display: none; }
		    &__description { display: none; }
		}

		&__overlay:hover {
		    background-color: black;
		    opacity: .8;
		    cursor: pointer;
		}

		&__overlay:hover > .improv-page__container__team-container__team__overlay__name {
		    display: unset;
		    color: white;
		    font-size: 1.5em;
		    text-align: center;
		    margin-bottom: .5vw;
		}
		&__overlay:hover > .improv-page__container__team-container__team__overlay__description {
		    display: unset;
		    color: white;
		    margin: 0 2vw;
		    text-align: center;
		    
		}

		.improv-team {
		    position: relative;
		    width: 30vw;
		    height: 30vw;
		}

		#image-track-96 { background-image: url("../../assets/improv/track_96.jpg"); }
		#image-blueberry { background-image: url("../../assets/improv/blueberry.png"); }
		#image-auto-correbt { background-image: url("../../assets/improv/auto_correbt.jpg"); }
		#image-film-club { background-image: url("../../assets/improv/film_club.jpg"); }
		#image-csi { background-image: url("../../assets/improv/csi.jpg"); }
		#image-michelle-impro { background-image: url("../../assets/improv/michelle-impro.jpg"); }
	    }
	    
	}
    }
}

@media only screen and (max-width: 600px) {
    .improv-page {
	height: fit-content;

	&__container {
	    height: fit-content;
	    margin-top: 0;

	    &__description {
		margin: 3vh auto;
	    }

	    &__team-container {
		height: fit-content;

		&__team {
		    height: fit-content;
		    width: 98vw;
		    display: flex;
		    flex-direction: column;
		    
		    .improv-team {
			width: 92vw;
			height: 92vw;
		    }

		    &__overlay {
			width: 75vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: unset;
			margin: 2vh auto;
			text-align: center;

			&__name { display: unset; }
			&__description { display: unset; }
		    }
		}
	    }
	}

	.quote-carousel { display: none; }
    }
}
