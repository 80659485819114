$stream-color: #464646;

.lil-saffron-page {
    display: flex;
    flex-direction: column;
    overflow: auto;

    #feedback-headline { margin: auto; }
    
    .image-carousel {
	#saffron-gallery-4 { background-image: url('../../assets/lil_saffron/show_1.jpg'); }
	#saffron-gallery-1 { background-image: url('../../assets/lil_saffron/show_2.jpg'); }
	#saffron-gallery-2 { background-image: url('../../assets/lil_saffron/show_3.jpg'); }
	#saffron-gallery-3 { background-image: url('../../assets/lil_saffron/show_4.jpg'); }
	#saffron-gallery-0 { background-image: url('../../assets/lil_saffron/show_5.jpg'); }
	#saffron-gallery-5 { background-image: url('../../assets/lil_saffron/option_4_gold.png'); }
	#saffron-gallery-6 { background-image: url('../../assets/lil_saffron/IMG_1981.jpg'); }
	#saffron-gallery-7 { background-image: url('../../assets/lil_saffron/IMG_1866.jpg'); }
	#saffron-gallery-8 { background-image: url('../../assets/lil_saffron/IMG_1760.jpg'); }
	#saffron-gallery-9 { background-image: url('../../assets/lil_saffron/Lil Saffron Ragu to Riches FINAL POSTER.png'); }
    }
    
    &__container {
	height: 100vh;
	width: 90%;
	margin: 0 auto;
	display: flex;
	
	&__description {
	    color: $stream-color;
	    margin: 0 5vw 0 5vw;
	    display: flex;
	    flex-direction: column;
	    text-align: center;

	    p { margin-bottom: 0vh; }
	    h2 { margin-bottom: 0vh; }

	}
	
	
	#lil-saffron-main-poster { background-image: url("../../assets/lil_saffron/main_poster_square.png"); }
	&__about {
	    width: 50%;
	    display: flex;
	    flex-direction: column;
	    align-items: center;

	    &__logos {
		display: flex;
		justify-content: center;
		margin: 3vh 0 -6vh 0;

		.logo {
		    margin: 0 2vw;
		    width: 16vh;
		    height: 15vh; /* You must set a specified height */
		}
		#soho-labs {
		    background-image: url("../../assets/lil_saffron/soho_theatre_labs__black.png");
		}
		#just-the-tonic { background-image: url("../../assets/lil_saffron/JTT_MASH_Address.png"); }
	    }
	}
    }
    .feedback {
	margin-top: 5vh;
	width: 100vw;
	height: 100vh; /* You must set a specified height */
	background-size: contain;
    }

    #audience-feedback { background-image: url("../../assets/lil_saffron/audience_feedback_edfringe_site.png"); }
    #twitter-feedback { background-image: url("../../assets/lil_saffron/twitter_feedback.png"); }
}


@media only screen and (max-width: 600px) {
    .lil-saffron-page {
	flex-direction: column;
	margin-bottom: 3vh;

	.feedback {
	    height: 55vw;

	}

	#feedback-headline { margin-top: 3vh; }
	
	&__container {
	    display: flex;
	    flex-direction: column;
	    height: fit-content;
	    margin-bottom: 5vh;
	    
	    &__about {
		width: 100%;
		height: fit-content;
		
		.quote-carousel { display: none; }
		
	    }
	    
	    &__description {
		display: flex;
		flex-direction: column;
		
		h2 {
		    text-align: center;
		    margin: 3vh auto 2vh;
		}

		
	    }
	}
    }
}
