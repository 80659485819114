.image-carousel {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    margin-top: 1vh;
    justify-content: center;

    h2 {
	margin: 3vh auto;
	color: #464646;
    }

    &__container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;

	&__button {
	    width: 10vh;
	    height: 10vh;
	    color: #464646;
	    border: 1px solid #464646;
	    border-radius: 10px;
	    font-size: 3em;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    margin: 2vw;
	}

	&__button.disabled {
	    /*color: #ccc9c9;
	    border-color: #ccc9c9;*/
	    cursor: not-allowed;
	    color: gray;
	    border-color: gray;
	}

	.image-container { 
	    width: 75vw;
	    height: 75vh; /* You must set a specified height */
	    background-size: contain;
	}
    }

    &__thumbnail-container {
	display: flex;
	margin: 0 auto;
	overflow: auto;
	width: 75vw;
	margin-top: .25vw;
	align-items: center;
	
	.image-container {
	    margin-right: .25vw;
	    width: 10vw;
	    height: 10vh; /* You must set a specified height */
	    background-position: center; /* Center the image */
	}

	.image-container.selected { border: 3px solid #fbd727; }
    }
}

@media only screen and (max-width: 600px) {
    .image-carousel {

	height: fit-content;
	
	&__container {

	    .fa {
		font-size: 1.5em;
	    }
	    
	    &__button {
		width: 5vh!important;
		height: 5vh!important;
	    }
	}

	.image-container {
	    width: 75vw;
	    height: 32vh;
	}

	&__thumbnail-container {
	    display: none;
	}
    }
}
