$stream-color: #464646;

.exile-page {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .image-carousel {
	#exile-gallery-4 { background-image: url('../../assets/exile/Exile photo 1.jpg'); }
	#exile-gallery-1 { background-image: url('../../assets/exile/Exile photo 2.jpg'); }
	#exile-gallery-2 { background-image: url('../../assets/exile/Exile photo 3.jpg'); }
	#exile-gallery-3 { background-image: url('../../assets/exile/Exile photo 4.jpg'); }
	#exile-gallery-0 { background-image: url('../../assets/exile/Exile photo 5.jpg'); }
	#exile-gallery-5 { background-image: url('../../assets/exile/Exile (L&U) - Poster.png'); }
	#exile-gallery-6 { background-image: url('../../assets/exile/exile.jpg'); }
    }
    
    &__container {
	height: 100vh;
	width: 90%;
	margin:  0 auto;
	display: flex;
	
	&__description {
	    color: $stream-color;
	    margin: 0 5vw 0 5vw;
	    display: flex;
	    flex-direction: column;

	    p { margin-bottom: -.5vh; }
	    h2 {
		margin-top: -1vh;
		margin-bottom: -.5vh;
	    }

	}
	
	#exile-main-poster { background-image: url("../../assets/exile/main_image.jpg"); }

	&__about {
	    width: 50%;
	    display: flex;
	    flex-direction: column;
	    align-items: center;

	    .quote-carousel__item__quote {
		font-size: 1em;
	    }
	    .quote-carousel__item__source {
		font-size: 1em;
	    }
	    
	    &__logos {
		display: flex;
		justify-content: center;
		margin: 3vh 0 -6vh 0;
		
		.logo {
		    margin-right: 3vw;
		    width: 14vh;
		    height: 14vh; /* You must set a specified height */
		}
		#blue-heart-theatre {
		    background-image: url("../../assets/exile/blue_heart_theatre.jpg");
		}
		#off-comm { background-image: url("../../assets/exile/offcom_commendation.jpg"); }
	    }
	}
    }
}

@media only screen and (max-width: 600px) {
    .exile-page {
	flex-direction: column;
	margin-bottom: 3vh;

	&__container {
	    display: flex;
	    flex-direction: column;
	    height: fit-content;
	    margin-bottom: 5vh;
	    
	    &__about {
		width: 100%;
		height: fit-content;
		margin-top: 0vh;
		overflow: unset;
		
		.quote-carousel { display: none; }
		
	    }
	    
	    &__description {
		display: flex;
		flex-direction: column;
		text-align: center;
		
		h2 {
		    text-align: center;
		    margin: 3vh auto 2vh;
		}

		
	    }
	}
    }
}
