$main-pink: #ff4a9f;
$light-pink: #edb7d0;
$dark-pink: #ab4075;
$pb-purple: #7C4DFF;
$gray-black: #281e23;
$main-black: #000000;
$main-white: #f4e9ee;
$true-white: #ffffff;
$stream-color: #464646;
$pause-yellow: #ffc04a;
$pause-gray: #ccc9c9;
$pb-font: 'Noto Sans', sans-serif;

$dark-purple: #492331;
$turquoise: #0c3a3c;
$salmon-pink: #ff7683;
$goldy-yellow: #fbd727;
$midnight-blue: #315271;

$cursor-color: rgb(255, 118, 131);

$font-1: Eudoxussans,sans-serif;

$project-size: 30vh;

body {
    margin: 0px;
    font-family: monospace;
    background-color: $pause-gray;
    font-size: 1.25em;
	
}

.image-container {
    width: 50%;
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 90vh; /* You must set a specified height */
    background-position: top; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.image-carousel {
    
}

.app-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .news-banner {
	display: flex;
	width: 100%;
	background-color: $stream-color;
	color: white;
	position: absolute;
	

	&__text {
	    width: fit-content;
	    text-align: center;
	    margin: 1.5vh auto;
	    cursor: pointer;
	}

	&__text:hover { color: $pause-gray; }

	i {
	    margin: 1.5vh;
	    position: absolute;
	    right: 0;
	    z-index: 3000;
	    cursor: pointer;
	}

	i:hover { color: $pause-gray; }
    }
    
    .header {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 5vh 0;
	color: $stream-color;

	&__name-header {
	    display: flex;
	    align-items: center;

	    #header-toggle {
		margin: 3vw;
		display: none;
	    }
	
	    #name-header {
		
	    }
	}

	&__pages-container {
	    display: flex;
	    height: fit-content;
	    align-items: center;
	    background-color: $pause-gray;
	    overflow-x: auto;
	    justify-content: space-evenly;

	    .mobile-only { display: none;}
	    
	    /*&__page-item.current {
	    text				-decoration: underline;
	}*/ 			   
	    
	    &__page-item {
		cursor: pointer;
		color: $stream-color;
		font-weight: 600;
	
		&__title {
		    display: flex;
		    margin: 2vh 3vh;
		
		    
		    i { margin-left: 2vh; }
		}
		
		&__dropdown {
		    position: absolute;
		    background-color: $pause-gray;
		    border-radius: .5em;
		    

		    &__item {
			margin: 2vh 3vh;
			margin-top: 1vh;
			color: $stream-color;
		    }
		}

	    }
	    div:hover {
		color: $main-black;
	    }
	    
	    &__more-container {
		display: flex;
		align-items: center;
		width: 50vw;
		height: 4vw;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1002;
		background-color: $pause-gray;
		justify-content: end;
		
		span {
		    font-weight: 600;
		    margin-right: 2vw;
		    cursor: pointer;
		    color: $stream-color;
		}
		
		span:hover {
		    color: $main-black;
		}
	    }
	}
    }

     .home-page__container { 
	
	//display: flex;
	height: 100vh;
	width: 90%;
	margin: 0 auto;
	display: flex;

	#main-quotes { margin-top: 0; }
	#main-image { background-image: url("../assets/denim_MK_3782.jpg"); }

	&__about {
	    width: 50%;
	    display: flex;
	    flex-direction: column;
	    align-items: center;

	    &__learn-more:hover {
		color: #000000;
		text-decoration: underline;
	    }
	    
	    &__learn-more {
		cursor: pointer;
		color: #464646;
		font-weight: 600;
		font-size: 1.5em;
		font-style: underline;
	    }

	    #representation-link {
		color: $stream-color;
		#bcc-link {
		    color: $stream-color;
		}

		#bcc-link:hover {
		    color: $main-black;
		}
	    }

	    h4 {
		margin: 10px 0;
		
	    }

	    h3 {
		font-size: 1.17em;
	    }

	    h2 {
		font-size: 1.5em;
	    }
	    
	    a#ticket-link {
		outline: 1px solid $stream-color;
		border-radius: 5px;
		padding: 5px;
		color: $stream-color;
		text-decoration: none;
		margin-top: 1vh;
		font-size: 1em;
		
	    }
	    div#link {
		margin: 10px 0;
		display: flex;
		
	    }
	    a#ticket-link:hover {
		color: $pause-gray;
		background-color: $stream-color;
	    }
	}

	#news {
	    color: $stream-color;
	    text-align: center;
	    max-width: 75%;

	    a {
		text-decoration: none;
		color: $stream-color;
	    }

	    a:hover { color: $main-black; }
	}
	#tag {
	    text-align: center;
	    color: $stream-color;
	    max-width: 75%;
	}
	
	&__socials {
	    display: flex;
	    width: fit-content;
	    
	    i {
		font-size: 5vh;
		margin: 2vh;
		color: $stream-color;
	    }
	    
	    i.fa-spotlight {
		content: url('../assets/SpotlightLogoRound.svg');
		height: 5vh;
	    }

	    i.fa-spotlight:hover { content: url('../assets/SpotlightLogoRound_hover.svg'); }
	    
	    i:hover {
		cursor: pointer;
		color: $main-black;
	    }
	}
    }   
}

@media only screen and (max-width: 600px) {
    body {

	.image-container {
	    width: 100%;
	    height: 100vw;;
	}
		    
	.app-container {
	    display: flex;
	    flex-direction: column;

	    .header {

		&__name-header {
		    display: flex;
		    align-items: center;
		    height: 5vh;
		    width: 80%;
		    justify-content: center;

		    #header-toggle {
			margin: 3vw;
			display: unset;
		    }
		    
		    #name-header {
			
		    }
		}


		&__pages-container {
		    display: none; 
		    position: absolute;
		    top: 10vh;
		    width: 80%;
		    overflow-x: unset;
		    justify-content: unset;
		    z-index: 1001;

		    .mobile-only { display: block;}
		    .dropdown { display: none; }
		    
		    &__page-item {
			margin: 3vh;
			font-size: 1.2em;

		    }

		}

		.show {
		    display: unset;
		}
	    }
	    
	    .home-page {
		width: 100vw;
		
		&__container {
		    display: flex;
		    flex-direction: column;
		    height: fit-content;
		    margin-bottom: 5vh;
		    
		    &__about {
			width: 100%;
			height: fit-content;
			text-align: center;
			padding-top: 3vh;

			
			//.quote-carousel { display: none; }
			
		    }

		}
		
	    }
	}
    }
}

