$stream-color: #464646;


.quote-carousel {

    display: flex;
    overflow: hidden;
    width: 100%;
    align-items: center;
	
    &__item {
	flex: 0 0 auto;
	width: 100%;
	justify-content: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: fit-content;

	&__quote {
	    width: fit-content;
	    max-width: 90%;
	    text-align: center;
	    margin: auto;
	    font-family: 'Roboto Slab',serif;
	    font-size: 1.5em;
	    font-style: italic;
	    font-weight: 600;
	    color: $stream-color;
	    margin: 1vh;
	}

	&__source {
	    width: fit-content;
	    margin: auto;
	    font-family: 'Roboto Slab',serif;
	    color: $stream-color;
	    font-size: 1.2em;
	}
	
    }
}

@media only screen and (max-width: 600px) {
    .quote-carousel__item {

	&__quote {
	    font-size: 1.2em;
	}

	&__source {
	    font-size: 1em;
	}
    }
}
